import gql from 'graphql-tag';

/**
 * TODO: complete this fragment
 */
export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
  }
`;


export const USER_ACTIVATE = gql`
  mutation UserActivate($where: UserWhereUniqueInput!) {
    UserActivate(where: $where) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

/**
 * TODO: complete this mutation
 */
export const USER_CREATE = gql`
  mutation UserCreate($data: UserCreateInput!) {
    UserCreate(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

/**
 * TODO: complete this mutation
 */
export const USER_DEACTIVATE = gql`
  mutation UserDeactivate($where: UserWhereUniqueInput!) {
    UserDeactivate(where: $where) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_DISABLE_2FA = gql`
  mutation Mutation {
    UserDisable2FA
  }
`;

export const USER_ENABLE_2FA = gql`
mutation Mutation($phoneNumber: String!) {
  UserEnable2FA(phoneNumber: $phoneNumber)
}
`;

/**
 * TODO: complete this mutation
 */
export const USER_LINK_PERMISSION = gql`
  mutation UserLinkPermission($permissionId: Int!, $userId: Int!) {
    UserLinkPermission(permissionId: $permissionId, userId: $userId) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

/**
 * TODO: complete this mutation
 */
export const USER_LINK_ROLE = gql`
  mutation UserLinkRole($roleId: Int!, $userId: Int!) {
    UserLinkRole(roleId: $roleId, userId: $userId) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

/**
 * TODO: complete this mutation
 */
export const USER_UNLINK_PERMISSION = gql`
  mutation UserUnLinkPermission($permissionId: Int!, $userId: Int!) {
    UserUnLinkPermission(permissionId: $permissionId, userId: $userId) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

/**
 * TODO: complete this mutation
 */
export const USER_UNLINK_ROLE = gql`
  mutation UserUnlinkRole($roleId: Int!, $userId: Int!) {
    UserUnlinkRole(roleId: $roleId, userId: $userId) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_UNVALIDATE_2FA = gql`
mutation Mutation($code: String!) {
  UserUnvalidate2FA(code: $code)
}`;

/**
 * TODO: complete this mutation
 */
export const USER_UPDATE = gql`
  mutation UserUpdate($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    UserUpdate(data: $data, where: $where) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

/**
 * TODO: complete this mutation
 */
export const USER_UPDATE_PROFILE = gql`
  mutation UserUpdateProfile($profileUpdateInput: ProfileUpdateInput!) {
    UserUpdateProfile(profileUpdateInput: $profileUpdateInput) {
      lastName
      id
    }
  }
`;

export const USER_VALIDATE_2FA = gql`
mutation Mutation($code: String!) {
  UserValidate2FA(code: $code)
}
`;

export const USER_FIND_ALL = gql`
  query UserFindAll(
    $where: UserWhereInput
    $take: Int
    $skip: Int
    $orderBy: [UserOrderByWithRelationInput!]
  ) {
    UserFindAll(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_FIND_ONE = gql`
  query UserFindOne($where: UserWhereUniqueInput!) {
    UserFindOne(where: $where) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;


export const GET_WhoAmI_QUERY = gql`
mutation AuthWhoAmI {
  AuthWhoAmI {
    id
    email
    Permissions2Users {
      permission {
        module
        name
      }
    }
    Roles2Users {
      role {
        name
        id
      }
    }
  }
}
`;
