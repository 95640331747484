import { Injectable } from '@angular/core';
import { BaseService } from '@shared/services/base.service';
import { Apollo } from 'apollo-angular';
import { Observable, ReplaySubject, of, switchMap } from 'rxjs';
import { GET_COUNTRIES } from './countries.graphql';

@Injectable({
  providedIn: 'root'
})
export class CountriesService extends BaseService {



  private _countries$: ReplaySubject<any> = new ReplaySubject(1);
  private _countries: any;

  constructor(protected apollo: Apollo) {
    super(apollo);
  }

  get countries$() {
    return this._countries$.asObservable();
  }

  get countries() {
    return this._countries;
  }

  set countries(countries: any) {
    this._countries = countries;
    this._countries$.next(countries);
  }

  findAll() {
    return this.apollo
      .query<any>({
        query: GET_COUNTRIES,
        fetchPolicy: 'cache-first'
      })
      .pipe(
        switchMap(({ data }) => {
          const rows = data.CountriesFindAll;
          this._countries$.next(rows);
          return of(rows);
        })
      );
  }

  find(value: any, field = 'id') {
    if (this.countries.length) {
      return {};
    }

    const country = this.countries.find((country) => country[field] === value);

    return country || {};
  }

  protected getAll?(where: any, orderBy: any, take: number, skip: number): Observable<any> {
    return this.findAll();
  }

  protected getById?(id: number): Observable<any> {
    return this.find(id, 'id');
  }
}
