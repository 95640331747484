// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphql: 'https://lcww-dev.targx.io/graphql',
  api: 'https://lcww-dev.targx.io/api',
  base: 'https://lcww-dev.targx.io/',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY_LOCAL_STORAGE: 'lcww_dev_access_token',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY_LOCAL_STORAGE_COMPANY: 'lcww_dev_company',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY_LOCAL_STORAGE_ROLES: 'lcww_dev_roles',

  passphrase:
    'Obedient Cool Feel Nature Patience Separation Shoot Theater Mud Committee 5',
  aeskey: 'XKviu4Pqhn@NcRfUjXnr6slsXSet!!8vGiC$$j9yPdSgVk/Ypr6slsXSet8vGi'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
