import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _items$: ReplaySubject<any> = new ReplaySubject(1);
  private _items: any;

  private _onTopButtonChange$: BehaviorSubject<string> = new BehaviorSubject<string>('joinclub');

  constructor() { }

  get onTpButtonChange$() {
    return this._onTopButtonChange$.asObservable();
  }

  set onTopButtonChange(value: any) {
    this._onTopButtonChange$.next(value);
  }
}
