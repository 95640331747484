import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { GET_WhoAmI_QUERY } from '@targx/graphql-queries/user';
import { Storage } from '@targx/libs/storage';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { Role, User } from './user.types';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  public _currentUser: User;
  public _roles: User;

  private _showSettingsPanels$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(true);
  private _showSettingsPanels: Boolean = true;

  private _selectModel$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public _selectModel: any;


  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private apollo: Apollo) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._currentUser = value;
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  set showSettingsPanels(value: Boolean) {
    // Store the value
    this._showSettingsPanels = value;
    this._showSettingsPanels$.next(value);
  }

  get showSettingsPanels$(): Observable<Boolean> {
    return this._showSettingsPanels$.asObservable();
  }

  set selectModel(value: any) {
    // Store the value
    if (value?.id === this._selectModel?.id)
      return;

    this._selectModel = value;
    this._selectModel$.next(value);
  }

  get selectModel$(): Observable<any> {
    return this._selectModel$.asObservable();
  }

  set roles(value: any) {
    this._roles = value;
  }
  get roles() {
    return (
      this._roles ||
      JSON.parse(Storage.get(environment.KEY_LOCAL_STORAGE_ROLES))
    );
  }

  /**
  *  Confirms if user is admin
  */
  get isAdmin() {
    return this._currentUser && this.roles.includes(Role.Admin);
  }

  /**
   *  Confirms if user is client
   */
  get isAgent() {
    return this._currentUser && this.roles.includes(Role.Agent);
  }

  /**
   *  Confirms if user is client
   */
  get isComercial() {
    return this._currentUser && this.roles.includes(Role.Comercial);
  }

  hasRole(roles: string[]): boolean {
    if (!this._currentUser) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }

    return roles.some((role: string) => this.roles.includes(role));
  }

  validateRole(roles: string[]): boolean {
    if (!this._currentUser) {
      return false;
    }

    return roles.some((role: string) => this.roles.includes(role));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: GET_WhoAmI_QUERY,
      })
      .pipe(
        switchMap(({ data }) => {
          this.user = data.AuthWhoAmI;
          return of(data.AuthWhoAmI);
        })
      );
  }


  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      }),
    );
  }

  toggleSettingsPanels(): void {
    if (this._showSettingsPanels) {
      this.showSettingsPanels = false;
      return;
    }

    this.showSettingsPanels = true;
    return;
  }
}
