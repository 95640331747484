import gql from 'graphql-tag';
import { USER_FRAGMENT } from './user';

export const AUTH_ACTIVATE_ACCOUNT = gql`
mutation AuthActivateAccount($password: String!, $token: String!) {
  AuthActivateAccount(password: $password, token: $token)
}`;

export const AUTH_FORGET_PASSWORD = gql`
mutation AuthForgetPassword($email: String!) {
  AuthForgetPassword(email: $email)
}`;

export const AUTH_LOGIN = gql`
mutation AuthLogin($email: String!, $password: String!) {
  AuthLogin(email: $email, password: $password) {
    accessToken
    email
    permissions
    roles
    userId
  }
}`;

export const AUTH_RECOVER_PASSWORD = gql`
mutation AuthRecoverPassword($password: String!, $token: String!) {
  AuthRecoverPassword(password: $password, token: $token)
}`;


export const AUTH_REGISTER = gql`
mutation AuthRegister($email: String!) {
  AuthRegister(email: $email) {
    ...UserFragment
  }
  ${USER_FRAGMENT}
}`;

/**
 * TODO: change this mutation 
 */
export const AUTH_VERIFY_LOGIN = gql`
mutation AuthVerifyLogin($otp: String!) {
  AuthVerifyLogin(otp: $otp) {
    email
    accessToken
  }
}`;

// /**
//  * TODO: complete this mutation
//  */
// export const AUTH_WHO_AM_I = gql`
// mutation AuthWhoAmI {
//   AuthWhoAmI {
    
//     email
//     id
//   }
// }`;
