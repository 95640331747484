import { Injectable } from '@angular/core';
import { BaseService } from '@shared/services/base.service';
import { Apollo } from 'apollo-angular';
import { Observable, of, switchMap } from 'rxjs';
import { GET_PLANS } from './plan.graphql';

@Injectable({
  providedIn: 'root'
})
export class PlansService extends BaseService {

  private _plans: any;

  constructor(protected apollo: Apollo) {
    super(apollo);
  }

  get plans() {
    return this._plans;
  }

  set plans(plans: any) {
    this._plans = plans;
  }

  getAll(where?: any, orderBy?: any, take?: number, skip?: number): Observable<any> {
    return this.apollo
      .query<any>({
        query: GET_PLANS,
      })
      .pipe(
        switchMap(({ data }) => {
          const rows = data.PlansFindAll;
          this.items = rows;
          this.plans = rows;
          return of(rows);
        })
      );
  }

  getById(id: number): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
