<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <img class="w-24" src="assets/images/logo/logo-text-on-dark.svg" alt="Logo image">
      </div>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-lcww-light-grey-blue">

  <!-- Header -->
  <div
    class="relative flex flex-col flex-0 justify-center w-full overflow-hidden z-49 shadow dark:shadow-none print:hidden">
    <!-- Top bar -->
    <div
      class="relative dark flex flex-auto justify-center w-full px-4 md:px-8 bg-lcww-light-grey-blue dark:bg-lcww-light-grey-blue">
      <div class="flex items-center w-full max-w-full h-16 sm:h-20">
        <!-- Logo -->
        <ng-container *ngIf="!isScreenSmall">
          <div class="flex items-center">
            <img class="w-18" src="assets/images/logo/logo-text-on-dark.svg" alt="Logo image">
          </div>
          <search [appearance]="'basic'" class="ml-12"></search>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
          </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
          <button mat-flat-button color="primary" class="text-white text-sm" routerLink="/judges-admin">
            <mat-icon svgIcon="feather:plus"></mat-icon>
            Go To Judges
          </button>
          <button mat-flat-button color="primary" class="text-white text-sm" routerLink="/admin">
            <mat-icon svgIcon="feather:plus"></mat-icon>
            Go To Admin
          </button>
          <ng-container *ngIf="onTopButtonChange$ | async as topbtn">
            <div [ngSwitch]="topbtn">
              <div *ngSwitchCase="'joinclub'">
                <button mat-flat-button color="primary" class="text-white text-sm">
                  <mat-icon svgIcon="feather:plus"></mat-icon>
                  Join Club
                </button>
              </div>
              <div *ngSwitchCase="'cats'">
                <button mat-flat-button color="primary" class="text-white text-sm" routerLink="/cats/new">
                  <mat-icon svgIcon="feather:plus"></mat-icon>
                  Add Cat
                </button>
              </div>
              <div *ngSwitchCase="'shows'">
                <button mat-flat-button color="primary" class="text-white text-sm" routerLink="/shows/new">
                  <mat-icon svgIcon="feather:plus"></mat-icon>
                  Add Show
                </button>
              </div>
              <div *ngSwitchCase="'litters'">
                <button mat-flat-button color="primary" class="text-white text-sm">
                  <mat-icon svgIcon="feather:plus"></mat-icon>
                  Add Litters
                </button>
              </div>
              <div *ngSwitchCase="'club'">
                <button mat-flat-button color="primary" class="text-white text-sm">
                  <mat-icon svgIcon="feather:plus"></mat-icon>
                  Add Club
                </button>
              </div>
              <div *ngSwitchCase="'cattery'">
                <button mat-flat-button color="primary" class="text-white text-sm">
                  <mat-icon svgIcon="feather:plus"></mat-icon>
                  Add Cattery
                </button>
              </div>
              <div *ngSwitchCase="'event'">
                <button mat-flat-button color="primary" class="text-white text-sm">
                  <mat-icon svgIcon="feather:plus"></mat-icon>
                  Add Event
                </button>
              </div>
              <div *ngSwitchDefault></div>
            </div>
          </ng-container>
          <languages *ngIf="false"></languages>
          <fuse-fullscreen class="hidden md:block" *ngIf="false"></fuse-fullscreen>
          <search [appearance]="'basic'" *ngIf="false"></search>
          <shortcuts *ngIf="false"></shortcuts>
          <messages *ngIf="false"></messages>
          <notifications *ngIf="false"></notifications>
          <user></user>
        </div>
      </div>
    </div>
    <!-- Bottom bar -->
    <ng-container *ngIf="!isScreenSmall">
      <div class="flex flex-auto justify-center px-4 md:px-8 bg-lcww-light-grey-blue dark:bg-lcww-light-grey-blue">
        <div class="relative flex items-center w-full max-w-full border-t">
          <fuse-horizontal-navigation class="relative z-10" [name]="'mainNavigation'"
            [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Content -->
  <div class="flex flex-auto justify-center w-full sm:px-6 md:px-8">
    <div
      class="flex flex-col flex-auto w-full sm:max-w-full sm:shadow-lg sm:rounded-xl sm:overflow-hidden bg-lcww-dark">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>
  </div>

  <!-- Footer -->
  <div class="relative flex flex-0 justify-center w-full px-6 md:px-8 z-49  bg-lcww-light-grey-blue print:hidden">
    <div class="flex items-center w-full max-w-full h-14 sm:h-20">
      <span class="font-medium text-secondary">LCWW &copy; {{currentYear}}</span>
    </div>
  </div>

</div>
