import { Route } from '@angular/router';
import { initialDataResolver, internalResolvers } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.routes'
          ),
      },
      {
        path: 'confirmation-forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-forgot-password/confirmation-forgot-password.routes'
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.routes'),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.routes'),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes'),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes'),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.routes'),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('app/modules/landing/home/home.routes'),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/admin/example/example.routes'),
      },
      {
        path: 'cats',
        loadChildren: () => import('app/modules/admin/cats/cats.routes'),
      },
      {
        path: 'litters',
        loadChildren: () => import('app/modules/admin/litters/litters.routes'),
      },
      {
        path: 'cattery',
        loadChildren: () => import('app/modules/admin/cattery/cattery.routes'),
      },
      {
        path: 'club',
        loadChildren: () => import('app/modules/admin/club/club.routes'),
      },
      {
        path: 'shows',
        loadChildren: () => import('app/modules/admin/shows/shows.routes'),
      },
    ],
  },

  {
    path: 'admin',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'futuristic',
      scheme: 'light',
      role: ['admin'],
    },
    resolve: {
      internal: internalResolvers,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/internal/dashboard/dashboard.routes'),
      },
      {
        path: 'breed',
        loadChildren: () => import('app/modules/internal/breed/breed.routes'),
      },
      {
        path: 'hair',
        loadChildren: () => import('app/modules/internal/hair/hair.routes'),
      },
      {
        path: 'color',
        loadChildren: () => import('app/modules/internal/color/color.routes'),
      },
      {
        path: 'eye-color',
        loadChildren: () => import('app/modules/internal/eye-color/eye.routes'),
      },
      {
        path: 'gender',
        loadChildren: () => import('app/modules/internal/gender/gender.routes'),
      },
      {
        path: 'pattern',
        loadChildren: () =>
          import('app/modules/internal/pattern/pattern.routes'),
      },
      {
        path: 'ears',
        loadChildren: () => import('app/modules/internal/ears/ears.routes'),
      },
      {
        path: 'legs',
        loadChildren: () => import('app/modules/internal/legs/legs.routes'),
      },
      {
        path: 'tails',
        loadChildren: () => import('app/modules/internal/tails/tails.routes'),
      },
      {
        path: 'cats',
        loadChildren: () => import('app/modules/internal/cat/cat.routes'),
      },
      {
        path: 'breeder',
        loadChildren: () => import('@modules/internal/breeder/breeder.routes'),
      },
      {
        path: 'cattery',
        loadChildren: () =>
          import('app/modules/internal/cattery/cattery.routes'),
      },
      {
        path: 'members',
        loadChildren: () =>
          import('app/modules/internal/members/members.routes'),
      },
      {
        path: 'shows',
        loadChildren: () =>
          import('app/modules/internal/shows/shows.routes'),
      },
      {
        path: 'clubs',
        loadChildren: () =>
          import('app/modules/internal/clubs/clubs.routes'),
      },
      {
        path: 'judges',
        loadChildren: () =>
          import('app/modules/internal/judges/judges.routes'),
      },
      {
        path: 'scoring',
        loadChildren: () =>
          import('app/modules/internal/scoring/scoring.routes'),
      },
    ],
  },

  {
    path: 'judges-admin',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'compact',
      scheme: 'light',
      role: ['admin'],
    },
    resolve: {
      internal: internalResolvers,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/judges-admin/dashboard/dashboard.routes'),
      },
      {
        path: 'events',
        loadChildren: () => import('app/modules/judges-admin/events/events.routes'),
      },
      {
        path: 'judgebook',
        loadChildren: () => import('app/modules/judges-admin/judgebook/judgebook.routes'),
      },
    ]
  }
];
