import { EnvironmentProviders, Provider } from "@angular/core";
import { provideIcons } from "@core/icons/icons.provider";
import { provideTransloco } from "@core/transloco/transloco.provider";

export const providerUploadTargx = (): Array<Provider | EnvironmentProviders> => {

  const providers: Array<Provider | EnvironmentProviders> = [

    // Transloco Config
    provideTransloco(),

    // Icons
    provideIcons(),

  ];

  return providers;
};
