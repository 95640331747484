import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, Provider, inject } from "@angular/core";
import { ApiService } from "./api.service";

export const provideApi = (): Array<Provider | EnvironmentProviders> => {
    const providers = [
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(ApiService),
            multi: true,
        }
    ];
    return providers;
};