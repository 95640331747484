import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfigService } from '@core/config/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  /**
   * Constructor
   */
  constructor() {
  }

  config = inject(ConfigService);

  ngOnInit(): void {
    this.config.onTopButtonChange = 'joinclub';
  }
}
