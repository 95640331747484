import { EnvironmentProviders, Provider, importProvidersFrom } from "@angular/core";
import { environment } from "environments/environment";
import { Storage } from '@targx/libs/storage';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';


export const createApollo = (httpLink: HttpLink) => {
    const basic = setContext((operation, context) => ({
        headers: {
            Accept: '*/*',
        },
    }));

    const auth = setContext((operation, context) => {
        const token = Storage.get(environment.KEY_LOCAL_STORAGE);

        if (token === null) {
            return {};
        } else {
            return {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
        }
    });

    const link = ApolloLink.from([
        basic,
        auth,
        httpLink.create({
            uri: environment.graphql,
        }),
    ]);

    const cache = new InMemoryCache();

    return {
        link,
        cache,
    };
};

export const providerGraphql = (): Array<Provider | EnvironmentProviders> => {

    return [
        importProvidersFrom(ApolloModule),
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ];
};