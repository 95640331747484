import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {

  protected _items: ReplaySubject<any> = new ReplaySubject<any>(1);
  protected _item: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(protected apollo: Apollo) { }

  get items$(): Observable<any> {
    return this._items.asObservable();
  }

  get item$(): any {
    return this._item.asObservable();
  }

  set item(value: any) {
    this._item.next(value);
  }

  set items(value: any) {
    this._items.next(value);
  }

  protected abstract getAll?(where?: any, orderBy?: any, take?: number, skip?: number): Observable<any>;

  protected abstract getById?(id: number): Observable<any>;
}
