
import gql from 'graphql-tag';

export const GET_PLANS = gql`
  query PlansFindAll {
    PlansFindAll {
      id
      name
      price
      description
    }
  }
`;
