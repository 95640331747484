import { EnvironmentProviders, Provider } from "@angular/core";
import { provideIcons } from "@core/icons/icons.provider";
import { provideTransloco } from "@core/transloco/transloco.provider";
import { providerUploadTargx } from "./components/tx-drag-drop-upload/tx-drag-drop-upload.provider";
import { provideApi } from "./services/api/api.provider";
import { providerGraphql } from "./services/graphql/graphql.provider";

export const provideTargx = (): Array<Provider | EnvironmentProviders> => {

  const providers: Array<Provider | EnvironmentProviders> = [

    // Transloco Config
    provideTransloco(),

    // Icons
    provideIcons(),

    // Api
    provideApi(),

    // Graphql
    providerGraphql(),

    providerUploadTargx()
  ];

  return providers;
};
