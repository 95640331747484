/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

const navigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Dashboard',
    type: 'basic',
    //icon: 'heroicons_outline:chart-pie',
    link: '/dashboard'
  },
  {
    id: 'ad-cats',
    title: 'Cats',
    type: 'basic',
    //icon: 'heroicons_outline:chart-pie',
    link: '/cats'
  },
  {
    id: 'ad-Litters',
    title: 'Litters',
    type: 'basic',
    //icon: 'heroicons_outline:chart-pie',
    link: '/litters'
  },
  {
    id: 'ad-cattery',
    title: 'Cattery',
    type: 'basic',
    //icon: 'heroicons_outline:chart-pie',
    link: '/cattery'
  },
  {
    id: 'ad-club',
    title: 'Club',
    type: 'basic',
    //icon: 'heroicons_outline:chart-pie',
    link: '/club'
  },
  {
    id: 'ad-shows',
    title: 'Events',
    type: 'basic',
    //icon: 'heroicons_outline:chart-pie',
    link: '/shows'
  }
];

const navigation2: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/admin/dashboard',
  },
  {
    id: 'directory',
    title: 'Directory',
    type: 'group',
    children: [
      {
        id: 'directory.cats',
        title: 'Cats',
        type: 'basic',
        link: '/admin/cats',
      },
      {
        id: 'directory.cattery',
        title: 'Cattery',
        type: 'basic',
        link: '/admin/cattery',
      },
      {
        id: 'directory.breeder',
        title: 'Breeder',
        type: 'basic',
        link: '/admin/breeder',
      },
      {
        id: 'directory.litters',
        title: 'Litters',
        type: 'basic',
        link: '/admin/litters',
      },
      {
        id: 'directory.clubs',
        title: 'Clubs',
        type: 'basic',
        link: '/admin/clubs',
      },
      {
        id: 'directory.judges',
        title: 'Judges',
        type: 'basic',
        link: '/admin/judges',
      }
    ], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },


  {
    id: 'shows',
    title: 'Events & Scoring',
    type: 'group',
    children: [
      {
        id: 'shows.shows',
        title: 'Events',
        type: 'basic',
        link: '/admin/shows',
      },
      {
        id: 'shows.scoring',
        title: 'Scoring',
        type: 'basic',
        link: '/admin/scoring',
      }
    ], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },

  {
    id: 'membership',
    title: 'Membership',
    type: 'group',
    children: [
      // {
      //   id: 'membership.member',
      //   title: 'Users',
      //   type: 'basic',
      //   link: '/admin/members',
      // },
      {
        id: 'membership.member',
        title: 'Fan',
        type: 'basic',
        link: '/admin/members',
      },
      {
        id: 'membership.member',
        title: 'Friend',
        type: 'basic',
        link: '/admin/members',
      },
      {
        id: 'membership.member',
        title: 'Lifetime',
        type: 'basic',
        link: '/admin/members',
      },
      {
        id: 'membership.subscriptions',
        title: 'Subscriptions',
        type: 'basic',
        link: '/admin/subscriptions',
      },

    ], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },


  {
    id: 'settings',
    title: 'Configuration',
    type: 'group',
    children: [
      {
        id: 'membership.packs',
        title: 'Packs',
        type: 'basic',
        link: '/admin/packs',
      },

    ], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'attributes',
    title: 'Attributes',
    type: 'aside',
    children: [
      {
        id: 'cats.breed',
        title: 'Breed',
        type: 'basic',
        link: '/admin/breed',
      },
      {
        id: 'cats.hair',
        title: 'Hair',
        type: 'basic',
        link: '/admin/hair',
      },
      {
        id: 'cats.color',
        title: 'Color',
        type: 'basic',
        link: '/admin/color',
      },
      {
        id: 'cats.eye',
        title: 'Eye Color',
        type: 'basic',
        link: '/admin/eye-color',
      },
      {
        id: 'cats.gender',
        title: 'Gender',
        type: 'basic',
        link: '/admin/gender',
      },
      {
        id: 'cats.pattern',
        title: 'Pattern',
        type: 'basic',
        link: '/admin/pattern',
      },
      {
        id: 'cats.ears',
        title: 'Ears',
        type: 'basic',
        link: '/admin/ears',
      },
      {
        id: 'cats.legs',
        title: 'Legs',
        type: 'basic',
        link: '/admin/legs',
      },
      {
        id: 'cats.tails',
        title: 'Tails',
        type: 'basic',
        link: '/admin/tails',
      },
    ], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];

const navigation3: FuseNavigationItem[] = [
  {
    id: 'jugdes-dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/judges-admin/dashboard',
  },
  {
    id: 'jugdes-events',
    title: 'Events',
    type: 'basic',
    icon: 'heroicons_outline:table-cells',
    link: '/judges-admin/events',
  },
  {
    id: 'jugdes-scoring',
    title: 'JudgeBook',
    type: 'basic',
    icon: 'heroicons_outline:trophy',
    link: '/judges-admin/judgebook',
  },
  {
    id: 'jugdes-master',
    title: 'Master',
    type: 'basic',
    icon: 'heroicons_outline:trophy',
    link: '/judges-admin/master',
  },
];

export const defaultNavigation: FuseNavigationItem[] = navigation;
export const compactNavigation: FuseNavigationItem[] = navigation3;
export const futuristicNavigation: FuseNavigationItem[] = navigation2;
export const horizontalNavigation: FuseNavigationItem[] = navigation;
