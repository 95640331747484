
import gql from 'graphql-tag';

export const GET_COUNTRIES = gql`
  query CountriesFindAll {
    CountriesFindAll {
      id
      name
      cca2
      cca3
      emoji
      phoneExt
    }
  }
`;
