import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AUTH_LOGIN } from '@targx/graphql-queries/authentication';
import { Storage } from '@targx/libs/storage';
import { Apollo } from 'apollo-angular';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { combineLatest, Observable, of, switchMap, tap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private apollo: Apollo
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    Storage.set(environment.KEY_LOCAL_STORAGE_COMPANY, token);
  }

  get accessToken(): string {
    return Storage.get(environment.KEY_LOCAL_STORAGE_COMPANY) ?? '';
  }
  /**
   * Setter & getter for access token
   */
  set accessRoles(token: string) {
    Storage.set(environment.KEY_LOCAL_STORAGE_ROLES, token);
  }

  get accessRoles(): string {
    return Storage.get(environment.KEY_LOCAL_STORAGE_ROLES) ?? '';
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
 * Sign in
 *
 * @param credentials
 */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this.apollo
      .mutate<any>({
        mutation: AUTH_LOGIN,
        fetchPolicy: 'network-only',
        variables: {
          email: credentials.email,
          password: credentials.password,
        },
      })
      .pipe(
        switchMap((result: any) => {
          const response = result.data.AuthLogin;

          // Store the access token in the local storage
          this.accessToken = response.accessToken;
          this.accessRoles = JSON.stringify(response.roles);

          // Set the authenticated flag to true
          this._authenticated = true;

          this._userService.roles = response.roles;

          // Return a new observable with the response
          return of(response);
        }),
        switchMap((response: any) => this._userService.get()),
        tap((response) => {
          // Store the user on the user service
          this._userService.user = response;
        })
      );

  }


  /**
  * Sign in using the access token
  */
  signInUsingToken(): Observable<any> {
    return combineLatest([this._userService.get()]).pipe(
      tap(([user]) => {
        // Store the user on the user service
        this._userService.user = user;

        // Set the authenticated flag to true
        this._authenticated = true;
      })
    );
  }


  /**
 * Sign out
 */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    Storage.remove(environment.KEY_LOCAL_STORAGE_COMPANY);
    Storage.remove(environment.KEY_LOCAL_STORAGE_ROLES);
    Storage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists, and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
